import { NextPage } from 'next'
import { useRouter } from 'next/dist/client/router'
import css from 'styled-jsx/css'
import { SVGLogoText } from '@shared/SVG'
import { useEffect, useRef, useState } from 'react'
import { EnterCodeForm, FillLoginForm, LoginMethod, LoginStep } from '@components/login'
import Loading from '@shared/Loading'
import { subscribeState, SubscribeStateKey, unsubscribeState } from '@helpers/stateSubscriber'
import { getLocalObject, sleep } from '@/helpers'

const LoginPage: NextPage = () => {
  const router = useRouter()
  const [loading, setLoading] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<string>(LoginStep.EMAIL)
  const [searchForm, setSearchForm] = useState<{ phone?: string; email?: string }>({
    phone: '',
    email: ''
  })
  const prevStepRef = useRef<any>()

  useEffect(() => {
    const authLocal = getLocalObject('AUTH_LOGIN')
    authLocal && setSearchForm({ phone: authLocal.phone || '', email: authLocal.email || '' })
    subscribeState(SubscribeStateKey.LOGIN_FORM, (loginForm: { phone?: string; email?: string }) =>
      setSearchForm(loginForm)
    )

    return () => unsubscribeState(SubscribeStateKey.LOGIN_FORM)
  }, [])

  useEffect(() => {
    const query = router.query
    switch (query.method) {
      case LoginMethod.CODE:
        setCurrentStep(LoginStep.CODE)
        prevStepRef.current = LoginStep.EMAIL
        break
      case LoginMethod.PHONE:
        setCurrentStep(LoginStep.PHONE)
        break
      case LoginMethod.EMAIL:
        setCurrentStep(LoginStep.EMAIL)
        break
    }
  }, [router])

  async function onNextStep(nextStep?: string) {
    prevStepRef.current = currentStep
    setLoading(true)
    await sleep(500)
    if (nextStep && typeof nextStep === 'string') {
      setCurrentStep(nextStep)
      setLoading(false)
      return
    }
    switch (currentStep) {
      case LoginStep.PHONE:
        setCurrentStep(LoginStep.EMAIL)
        break
      case LoginStep.EMAIL:
        setCurrentStep(LoginStep.PHONE)
        break
      case LoginStep.CODE:
        setCurrentStep(LoginStep.ORDER)
        break
      case LoginStep.ORDER:
        setCurrentStep(() => (searchForm.email ? LoginStep.PHONE : LoginStep.EMAIL))
        break
    }
    setLoading(false)
  }

  return (
    <>
      <style jsx>{pageStyle}</style>
      {loading && <Loading />}
      <div className="login-page has-text-centered">
        <SVGLogoText className="login-page__logo has-pointer" onClick={() => router.push('/')} />
        {currentStep !== LoginStep.CODE ? (
          <FillLoginForm
            currentStep={currentStep}
            nextStep={(nextStep?: any) => onNextStep(nextStep)}
            searchForm={searchForm}
          />
        ) : (
          <EnterCodeForm
            nextStep={() => onNextStep()}
            prevStep={prevStepRef.current}
            searchForm={searchForm}
          />
        )}
      </div>
    </>
  )
}

const pageStyle = css.global`
  .login-page {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: var(--indigo-1);

    padding-top: 4rem;

    &__logo {
      margin-bottom: 48px;
    }
  }
`

export default LoginPage
